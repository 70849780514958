<template>
  <div class="profile-container">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <Breadcrumb :items="breadCrumbs" />
    <v-container class="py-0">
      <v-row>
        <v-col
          cols="12"
          sm="5"
          md="3"
          order="2"
          order-sm="1"
          class="primary lighten-2 mt-3 pt-2 left-menu-dashboard"
          v-if="!hideDashboardMenu"
        >
          <Dashboard :leftMenu="true" :key="dashboardKey"
        /></v-col>
        <v-col
          cols="12"
          :sm="hideDashboardMenu ? '12' : '7'"
          :md="hideDashboardMenu ? '12' : '9'"
          order="1"
          order-sm="2"
        >
          <v-tabs
            v-if="showTcoMenu && isOroUser"
            centered
            class="profile-navigation-menu"
          >
            <v-tabs-slider />
            <v-tab :to="{ name: 'TcoPerks' }" value="TcoPerks"
              ><span
                ><v-icon>$gift</v-icon>{{ $t("dashboard.menu.gifts") }}</span
              ></v-tab
            >
            <v-tab :to="{ name: 'TcoRules' }" value="TcoRules"
              ><span
                ><v-icon>$gift</v-icon>{{ $t("dashboard.menu.rules") }}</span
              ></v-tab
            >
          </v-tabs>
          <v-container class="profile-section">
            <router-view></router-view>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.profile-container {
  .profile-navigation-menu.v-tabs {
    .v-tabs-slider {
      background-color: $gold !important;
    }
    .v-tab {
      max-width: 25%;
      padding: 0px 20px;
      letter-spacing: 0.1px !important;
      font-weight: bold;
      border-bottom: 2px solid var(--v-primary-base);
      @media #{map-get($display-breakpoints, 'xs-only')} {
        padding: 0px 10px;
      }

      span {
        font-size: 12px;
        text-transform: none;
        color: var(--v-primary-base);
        .v-icon {
          color: inherit;
          margin-right: 5px;
        }
      }
      &.v-tab--active {
        span {
          color: $gold !important;
        }
      }
    }
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import get from "lodash/get";
import { mapGetters } from "vuex";
import Dashboard from "./Dashboard.vue";

export default {
  name: "Profile",
  components: { Breadcrumb, Dashboard },
  data() {
    return {
      dashboardKey: 1,
      hideDashboardMenu: false
    };
  },
  methods: {
    incrementKey() {
      this.dashboardKey++;
    }
  },
  computed: {
    ...mapGetters({
      getLegalById: "cart/getLegalById"
    }),
    isOroUser() {
      return this.getLegalById(12);
    },
    showTcoMenu() {
      let result = get(this, "$route.meta.showTcoMenu", false);
      return result;
    },
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ to: { name: "HomeSite" }, text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;

      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            to: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            exact: true,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  },
  created() {
    global.EventBus.$on("updateDashboard", this.incrementKey);
    if (this.$route.meta.hideDashboardMenu) {
      this.hideDashboardMenu = true;
    } else this.hideDashboardMenu = false;
  },
  watch: {
    $route(to) {
      if (to.meta.hideDashboardMenu) {
        this.hideDashboardMenu = true;
      } else this.hideDashboardMenu = false;
    }
  }
};
</script>
